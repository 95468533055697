import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  CircularProgress,
  Button,
} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useParams } from "react-router-dom";

const RolePermissions = () => {
  const { id } = useParams();
  const [modules, setModules] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const token = localStorage.getItem('token_key');
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/rolemodules/byrole/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setModules(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };

    fetchModules();
  }, [id]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = localStorage.getItem('token_key');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/rolespermission/byrole/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setPermissions(response.data.data);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [id]);

  useEffect(() => {
    const initialSelectedValues = {};
    permissions.forEach((permission) => {
      const { role_module_id, permission_name, status } = permission;
      initialSelectedValues[`${role_module_id}_${permission_name}`] = status === 1 ? 1 : 0;
    });
    setSelectedValues(initialSelectedValues);
  }, [permissions]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [value]: checked ? 1 : 0,
    }));
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token_key');
    try {
      const permissionsToUpdate = Object.entries(selectedValues).map(([key, status]) => {
        const [moduleId, permissionName] = key.split("_");
        const permission_id = permissionsByModule[moduleId]?.[permissionName]?.permission_id;
        return {
          permission_id,
          status: status, // Status is already 1 or 0
        };
      });

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions/update`,
        { permissions: permissionsToUpdate },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        alert("Permissions updated successfully!");
      } else {
        alert("An error occurred while updating permissions.");
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      alert("An error occurred while updating permissions.");
    }
  };

  // Build a dictionary of permissions by module_id and permission_name
  const permissionsByModule = {};
  permissions.forEach((permission) => {
    const { role_module_id, permission_name, status, permission_id } = permission;
    if (!permissionsByModule[role_module_id]) {
      permissionsByModule[role_module_id] = {};
    }
    permissionsByModule[role_module_id][permission_name] = {
      status,
      permission_id,
    };
  });

  const permissionTypes = ["view", "create", "update", "delete"];

  const getPermissionPosition = (type) => {
    switch (type) {
      case "view": return 1;
      case "create": return 2;
      case "update": return 3;
      case "delete": return 4;
      default: return 0;
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Grid
        container
        sx={{
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px",
          marginBottom: "15px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" gutterBottom>
            Role Permission Access
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <AddCircleOutlinedIcon sx={{ marginRight: "3px" }} />
            Apply
          </Button>
        </Box>
      </Grid>

      {modules.length > 0 ? (
        <Box mt={4}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={16} justifyContent="center">
              <Box p={3} sx={{ textAlign: "center", margin: "0 auto", border: "1px solid #f0f0f0" }}>
                <Grid container spacing={2}>
                  {/* Header Row */}
                  <Grid item xs={4} display="flex" justifyContent="center">
                    <Box sx={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "4px",
                        width: "60%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fafafa",
                        mb: 2
                    }}>
                      <Typography>Modules</Typography>
                    </Box>
                  </Grid>

                  {/* Header for Permission Types */}
                  {permissionTypes.map((type) => (
                    <Grid item xs={2} key={type} display="flex" justifyContent="center">
                      <Box sx={{
                          border: "1px solid #ccc",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          borderRadius: "4px",
                          width: "80%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fafafa",
                          mb: 2
                      }}>
                        <Typography>{type.charAt(0).toUpperCase() + type.slice(1)}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {/* Role Rows */}
                {modules.map((role) => {
                  // Get all permissions for this module
                  const modulePermissions = permissionsByModule[role.role_module_id] || {};
                  
                  // Create an array of permissions sorted by position
                  const sortedPermissions = permissionTypes.map(type => ({
                    type,
                    position: getPermissionPosition(type),
                    exists: !!modulePermissions[type],
                    key: `${role.role_module_id}_${type}`
                  }));

                  return (
                    <Grid container key={role.role_module_id} spacing={2}>
                      {/* Role Name with Outline */}
                      <Grid item xs={4} display="flex" justifyContent="center">
                        <Box sx={{
                            border: "1px solid #ccc",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            borderRadius: "4px",
                            width: "60%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#fafafa",
                            mb: 2
                        }}>
                          <Typography>{role.role_module_display_name}</Typography>
                        </Box>
                      </Grid>

                      {/* Render Permission Columns */}
                      {sortedPermissions.map(({ type, position, exists, key }) => (
                        <Grid item xs={2} key={type} display="flex" justifyContent="center">
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              width: "45%",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                              mb: 2,
                              minHeight: "40px", // Ensure consistent height
                            }}
                          >
                            {exists ? (
                              <>
                                {/* <Typography variant="body2" sx={{ marginRight: 1 }}>
                                  {position}
                                </Typography> */}
                                <Checkbox
                                  value={key}
                                  checked={selectedValues[key] === 1}
                                  onChange={handleCheckboxChange}
                                />
                              </>
                            ) : (
                              // Empty space holder when permission doesn't exist
                              <Box sx={{ width: "100%", height: "100%" }} />
                            )}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default RolePermissions;
