import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Box,
  TablePagination,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";

const TeacherList = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [courseCategories, setCourseCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedTeachSubCategory, setSelectedTeachSubCategory] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [teachercatId, setTeachercatId] = useState("");
  const [teacherSubcatId, setTeacherSubcatId] = useState("");
  const [teacherCat, setTeacherCat] = useState([]);
  const [selectedTeacherCategory, setSelectedTeacherCategory] = useState("");

  const [schools, setSchools] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [departments, setDepartments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteTeacherId, setDeleteTeacherId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTeachers();
    fetchCourseCategories();
    fetchCourseSubCategories();
    fetchTeacherCategory();
  }, [
    currentPage,
    pageSize,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedCourse,
  ]);

  const [filter, setFilter] = useState({
    schoolCategory: "",
    schoolSubCategory: "",
    school: "",
    grade: "",
  });

  // Fetch Teachers
  const fetchTeachers = async () => {
    try {
      const token = getToken();
      if (token) {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/teachers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage + 1, // Adjusting for 0-based index (frontend)
              limit: pageSize,
              search: searchTerm,
              selectedCategory, // Passing selected school category
              schoolSubCategory: selectedSubCategory, // Passing selected school
              selectedSchool: selectedSchool,
              selectedTeacherCategory,
              selectedTeachSubCategory,
            },
          }
        );
        const data = response.data;
        if (data?.data) {
          setTeachers(data.data);
          setTotalItems(data.pagination.totalItems);
        } else {
          setError("No teachers found");
        }
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        // get list of filter units
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/teachers/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeachers(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/getschool/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchools(subcategoryResponse4.data.data);
        console.log("get school :", subcategoryResponse4.data.data);

        // get list of filter units
        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/teachers/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeachers(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }
      if (selectedSchool) {
        // get list of filter units
        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/teachers/${selectedCategory}/${selectedSubCategory}/${selectedSchool}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeachers(subcategoryResponse9.data.data);
        console.log(
          "filtered teachers with school:",
          subcategoryResponse9.data.data
        );
      }
    } catch (err) {
      console.log("Failed to fetch course subcategories");
    }
  };

  // fetch teacher category
  const fetchTeacherCategory = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teachers/category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeacherCat(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch teacher categories");
    }
  };

  const fetchTeacherSubCategories = async () => {
    if (!selectedTeacherCategory) {
      return;
    }

    try {
      console.log("selected teacher :", selectedTeacherCategory);

      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teachers/sub_category/getsubcat/${selectedTeacherCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeacherSubCategories(response.data.data || []);
        // get list of filter units
        const teachsubcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/teachers/${selectedTeacherCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeachers(teachsubcategoryResponse1.data.data);
        console.log("filtered units:", teachsubcategoryResponse1.data.data);
      }

      if (selectedTeachSubCategory) {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/teachers/sub_category/getsubcat/${selectedTeacherCategory}/${selectedTeachSubCategory}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setTeacherSubCategories(response.data.data || []);
          // get list of filter units
          const subcategoryResponse29 = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/list/teachers/${selectedCategory}/${selectedSubCategory}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setSchools(subcategoryResponse29.data.data);
          console.log("filtered schools:", subcategoryResponse29.data.data);
        }
      }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  useEffect(() => {
    fetchTeacherCategory();
    fetchTeacherSubCategories();
  }, [teachers, teachercatId, selectedTeachSubCategory]);

  // Fetch Course Subcategories
  useEffect(() => {
    fetchCourseSubCategories();
  }, [selectedCategory, selectedSubCategory, selectedSchool]);

  // Handle Search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(`Filter updated: ${name} = ${value}`);
  };
  // Handle Category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle teacher category Change
  const handleTeacherCategoryChange = (event) => {
    setSelectedTeacherCategory(event.target.value);
    setSelectedTeachSubCategory("");
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  const handleTeacherSubCategoryChange = (event) => {
    setSelectedTeachSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };
  // Handle Subcategory Change
  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  // Handle Course Change
  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(0); // Reset to the first page
  };

  // Handle Page Change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Handle Rows Per Page Change
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Teachers List</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  courseCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                } // Find the selected category by its ID
                onChange={(e, newValue) => {
                  setSelectedCategory(newValue ? newValue._id : ""); // Update selectedCategory with the selected category's ID
                }}
                options={courseCategories.sort((a, b) =>
                  a.school_name.localeCompare(b.school_name)
                )} // Sort categories A to Z by school_name
                getOptionLabel={(option) => option.school_name} // Display the school_name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by category ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.school_name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>

     {  selectedCategory &&   <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  courseSubCategories.find(
                    (subCategory) => subCategory._id === selectedSubCategory
                  ) || null
                } // Find the selected subcategory by its ID
                onChange={(e, newValue) => {
                  setSelectedSubCategory(newValue ? newValue._id : ""); // Update selectedSubCategory with the selected subcategory's ID
                }}
                options={courseSubCategories.sort((a, b) =>
                  a.SubCategory_name.localeCompare(b.SubCategory_name)
                )} // Sort subcategories A to Z by SubCategory_name
                getOptionLabel={(option) => option.SubCategory_name} // Display the subcategory name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by subcategory ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School Subcategory"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>
}
          {selectedSubCategory &&
            <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  schools.find((school) => school._id === selectedSchool) ||
                  null
                } // Find the selected school by its ID
                onChange={(e, newValue) => {
                  setSelectedSchool(newValue ? newValue._id : ""); // Update selectedSchool with the selected school's ID
                }}
                options={schools.sort((a, b) =>
                  a.school_name.localeCompare(b.school_name)
                )} // Sort schools A to Z by school_name
                getOptionLabel={(option) => option.school_name} // Display the school name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Match by school ID
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="School"
                    size="small"
                    variant="outlined"
                  />
                )}
                disableClearable={false} // Allow clearing the selection
              />
            </FormControl>
          </Grid>}
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Partner ID </TableCell>
              <TableCell>School Category</TableCell>
              <TableCell>School Subcategory</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Qualification</TableCell>
              <TableCell>Hire Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : teachers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No teachers available
                </TableCell>
              </TableRow>
            ) : (
              teachers.map((teacher, index) => (
                <TableRow key={teacher._id}>
                  <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>
                    {teacher.partnerData?.partner_id}
                  </TableCell>
                  <TableCell>{teacher.school_category?.school_name}</TableCell>
                  <TableCell>
                    {teacher.school_sub_category?.SubCategory_name}
                  </TableCell>
                  <TableCell>{teacher.school?.school_name}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  <TableCell>{teacher.mobile}</TableCell>
                  <TableCell>{teacher.qualification}</TableCell>
                  <TableCell>{new Date(teacher.hire_Date).toLocaleDateString()}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TeacherList;
