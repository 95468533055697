import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const AddProductsList = () => {
  const [product, setProduct] = useState({
    product_id: "",
    product_name: "",
    product_category_id: "",
    product_sub_category_name: "",
    course_id: "",
    department_id: "",
    isDeleted: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/category/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data.data || []);
      } }catch (err) {
        console.error("Failed to fetch categories", err);
      }
    };

    const fetchSubCategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/product/category_sub/nopagination`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSubCategories(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch subcategories", err);
      }
    };

    const fetchCourses = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch courses", err);
      }
    };

    const fetchDepartments = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/department`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setDepartments(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch departments", err);
      }
    };

    fetchCategories();
    fetchSubCategories();
    fetchCourses();
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const filtered = subCategories.filter(
        (subcategory) => subcategory.product_id === selectedCategory
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [selectedCategory, subCategories]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "product_category_id") {
      setSelectedCategory(value);
    }
    setProduct({
      ...product,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const {
      product_id,
      product_name,
      product_category_id,
      product_sub_category_name,
      department_id,
    } = product;
    if (
      !product_id ||
      !product_name ||
      !product_category_id ||
      !product_sub_category_name ||
      !department_id
    ) {
      setError("All fields are required");
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbarMessage(error);
      setSnackbarOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/product`,
          product,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Product added successfully");
        setSnackbarOpen(true);
        navigate("/product_list", { state: { snackbarMessage: 'Product added successfully' } });
      }
    } catch (err) {
      setError(`Failed to add product ${err.response.data.message || err.message}`);
      setSnackbarMessage(`Failed to add product ${err.response.data.message || err.message}`);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/product_list"); // Navigate to the desired route on cancel
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4" gutterBottom>
          Add Product{" "}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Product Category</InputLabel>
          <Select
            name="product_category_id"
            value={product.product_category_id}
            onChange={handleChange}
            label="Product Category"
            error={!product.product_category_id && Boolean(error)}
          >
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.product_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Product Subcategory</InputLabel>
          <Select
            name="product_sub_category_name"
            value={product.product_sub_category_name}
            onChange={handleChange}
            label="Product Subcategory"
            error={!product.product_sub_category_name && Boolean(error)}
          >
            {filteredSubCategories.length > 0 ? (
              filteredSubCategories.map((subCategory) => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                  {subCategory.product_sub_category_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No subcategories available
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Department</InputLabel>
          <Select
            name="department_id"
            value={product.department_id}
            onChange={handleChange}
            label="Department"
            error={!product.department_id && Boolean(error)}
          >
            {departments.length > 0 ? (
              departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No departments available
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          label="Product ID"
          variant="outlined"
          fullWidth
          margin="normal"
          name="product_id"
          value={product.product_id}
          onChange={handleChange}
          error={!product.product_id && Boolean(error)}
          helperText={!product.product_id && error}
        />
        <TextField
          label="Product Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="product_name"
          value={product.product_name}
          onChange={handleChange}
          error={!product.product_name && Boolean(error)}
          helperText={!product.product_name && error}
        />
        <Box mt={2} display="flex"  >
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleCancel}
            sx={{ backgroundColor:"#e0e0e0" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ ml: 3 , backgroundColor:"#e0e0e0" }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Add Product"}
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddProductsList;
