import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  TextField,
  TablePagination,
  Button,
  IconButton,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import Rating from "@mui/material/Rating";
import SearchIcon from "@mui/icons-material/Search";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const StudentPage = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortTerm, setSortTerm] = useState("grade-ascending"); // default sorting value
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0); // Initialize totalItems as number
  const [selectedGrade, setSelectedGrade] = useState({
    grade: "",
    gradeDivision: "",
  });
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [canCreate, setCanCreate] = useState(false); // Toggle between showing cropper and input
  const [canView, setCanView] = useState(false); // Toggle between showing cropper and input
  const [canUpdate, setCanUpdate] = useState(false); // Toggle between showing cropper and input
  const [canDelete, setCanDelete] = useState(false); // Toggle between showing cropper and input
  const [filter, setFilter] = useState({
    schoolCategory: "",
    schoolSubCategory: "",
    school: "",
    grade: "",
  });
  const [grades, setGrades] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [school, setSchool] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [schools, setSchools] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState("");

  useEffect(() => {
    fetchStudents();
    fetchCourseCategories();
    fetchGrades();
    // fetchNumCourses();
  }, [page, rowsPerPage, searchTerm]);
  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('token_key');
      
      if (token) {
        try {
          const roleResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions/4`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          const rolePermissions = roleResponse.data.data;
          
          // Check role permissions or set the role
          if (rolePermissions ) {
            // navigate("/unauthorized");
            // console.log("You are not authorized to create a school",rolePermissions);
            setCanCreate(rolePermissions.create);
            setCanView(rolePermissions.view);
            setCanUpdate(rolePermissions.update);
            setCanDelete(rolePermissions.delete);
          }
        } catch (error) {
          console.error("Error fetching role permissions:", error);
          // navigate("/unauthorized"); // Redirect in case of error
        }
      }
    };

    fetchPermissions();
  }, []);
  // Fetch grades data

  const fetchGrades = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/grade`
      );
      setGrades(response.data.data); // Adjust based on your API response structure
    } catch (err) {
      console.error("Failed to fetch grades:", err);
    }
  };

  useEffect(() => {
    fetchStudentsIfGrade();
  }, [selectedGrades]);

  const fetchStudentsIfGrade = async () => {
    if (!selectedGrades) {
      return;
    }
    try {
      const token = getToken();
      if (token) {
        const gradeschool = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/studlist/grade/${selectedGrades}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setStudents(gradeschool.data.data);
        console.log("filtered gradeschool:", gradeschool.data.data);
      }
    } catch (error) {
      setError("Failed to fetch grade ");
    }
  };

  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/list/studlist/bypartner`,
        {
          params: {
            page: page + 1, // Backend expects 1-based page index
            limit: rowsPerPage,
            searchTerm: searchTerm,
            selectedCategory, // Passing selected school category
            schoolSubCategory: selectedSubCategory, // Passing selected school
            selectedSchool,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data);
        console.log(data.data);

        setTotalItems(data.pagination.totalItems); // Correctly set totalItems
      } else {
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch students categories");
    }
  };

  // Fetch Course Subcategories
  useEffect(() => {
    fetchCourseSubCategories();
  }, [selectedCategory, selectedSubCategory, selectedSchool]);

  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        // get list of filter units
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/studlist/bypartner/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setStudents(subcategoryResponse1.data.data);
        console.log("filtered students:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/getschool/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchools(subcategoryResponse4.data.data);
        console.log("get students :", subcategoryResponse4.data.data);

        // get list of filter units
        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/studlist/bypartner/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setStudents(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }
      if (selectedSchool) {
        console.log("selected school///////:", selectedSchool);
        // get list of filter units
        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/studlist/bypartner/${selectedCategory}/${selectedSubCategory}/${selectedSchool}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setStudents(subcategoryResponse9.data.data);
        console.log(
          "filtered students with school:",
          subcategoryResponse9.data.data
        );
      }
    } catch (err) {
      setError("Failed to fetch students subcategories");
    }
  };

  // Handle Category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };
  // Handle Subcategory Change
  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  const handleGradeChange = (event) => {
    setSelectedGrades(event.target.value);

    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page to 0 when search term changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Function to handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(`Filter updated: ${name} = ${value}`);
  };

  // Use filteredSchools if filters are applied, otherwise show all schools
  const schoolsToDisplay =
    filteredStudent.length > 0 ? filteredStudent : students;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4">Students</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box paddingLeft={0}></Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Category */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courseCategories.find(
                      (category) => category._id === selectedCategory
                    ) || null
                  } // Find the selected category by its ID
                  onChange={(e, newValue) => {
                    setSelectedCategory(newValue ? newValue._id : ""); // Update selectedCategory with the selected category's ID
                    fetchStudents();
                  }}
                  options={courseCategories.sort((a, b) =>
                    a.school_name.localeCompare(b.school_name)
                  )} // Sort categories A to Z by school_name
                  getOptionLabel={(option) => option.school_name} // Display the category name in the dropdown
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by category ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Category"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>

            {/* Subcategory */}
           {selectedCategory && 
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courseSubCategories.find(
                      (subCategory) => subCategory._id === selectedSubCategory
                    ) || null
                  } // Find the selected subcategory by its ID
                  onChange={(e, newValue) => {
                    setSelectedSubCategory(newValue ? newValue._id : ""); // Update selectedSubCategory with the selected subcategory's ID
                  }}
                  options={courseSubCategories.sort((a, b) =>
                    a.SubCategory_name.localeCompare(b.SubCategory_name)
                  )} // Sort subcategories A to Z by name
                  getOptionLabel={(option) => option.SubCategory_name} // Display the subcategory name in the dropdown
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by subcategory ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Subcategory"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>}

            {/* School */}

            {selectedSubCategory &&
              <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    schools.find((school) => school._id === selectedSchool) ||
                    null
                  } // Find the selected school by ID
                  onChange={(e, newValue) => {
                    setSelectedSchool(newValue ? newValue._id : ""); // Update selectedSchool with the selected school's ID
                  }}
                  options={schools.sort((a, b) =>
                    a.school_name.localeCompare(b.school_name)
                  )} // Sort schools A to Z
                  getOptionLabel={(option) => option.school_name} // Display the school name in the dropdown
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Match by school ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>
}
            {/* Grade */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    grades.find((grade) => grade._id === selectedGrades) || null
                  } // Find the selected grade by ID
                  onChange={(e, newValue) => {
                    setSelectedGrades(newValue ? newValue._id : ""); // Update selectedGrades with the selected grade's ID
                    fetchStudents();
                  }}
                  options={grades.sort((a, b) =>
                    a.gradename.localeCompare(b.gradename)
                  )} // Sort grades A to Z
                  getOptionLabel={(option) => option.gradename} // Display the grade name in the dropdown
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  // Match by grade ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grade"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  disableClearable={false} // Allow clearing the selection
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {canView === 1 ? (
        <Grid
          container
          spacing={2}
          sx={{
            padding: 2,
            paddingRight: { xs: 0, sm: 0, md: 2 },
          }}
        >
                   

          {schoolsToDisplay.length > 0 ? (
            schoolsToDisplay.map((student) => {
              // Split grade_division into grade and division
              const [_, grade, division] = student.grade_division
                ? student.grade_division.split(" ")
                : ["", "N/A", "N/A"];

              return (
                <Grid item xs={12} sm={12} md={6} xl={4} key={student._id}>
                  <Card
                    sx={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "12px",
                      height: "100v",
                      // p:'2'
                    }}
                  >
                    {/* Top Section: Name, ID, Grade, and Division */}
                    <Box sx={{ backgroundColor: "#e0dfda", padding: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* Student Name */}
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "300",
                            color: "#000",
                            display: "flex",
                          }}
                        >
                          {student.schoolData.school_name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Student Name */}
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "#000" }}
                        >
                          {student.name}
                        </Typography>
                      </Box>

                      {/* ID, Grade, Division */}
                      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}
                        >
                          {`ID-${student.student_id}`}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}
                        >
                          {`Grade-${grade}`}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#a0947c",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            fontSize: "12px",
                            color: "#fff",
                          }}
                        >
                          {`Division-${division}`}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Bottom Section: Profile Picture, Details, and Rating */}
                    <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
                      {/* Profile Picture & Details */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mt: 2,
                        }}
                      >
                        {/* Profile Picture */}
                        <Box sx={{ marginRight: "2px" }}>
                          <img
                            src={student.profile_url || "/default-profile.png"}
                            alt={`${student.name}'s profile`}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              border: "1px solid #333",
                              marginRight: "15px",
                            }}
                          />
                        </Box>

                        {/* Details and Rating aligned vertically */}
                        <Box
                          flexGrow={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {/* Details */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <MailIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.email}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <PhoneIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "4px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.mobile}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <SchoolIcon
                                sx={{
                                  fontSize: "22px",
                                  mr: 1,
                                  color: "#fff",
                                  backgroundColor: "#a0947c",
                                  padding: "3px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography variant="body2">
                                {student.totalCourses || "N/A"}
                              </Typography>
                            </Box>
                          </Box>

                          {/* Rating */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <ThumbUpIcon
                              sx={{
                                fontSize: "22px",
                                mr: 1,
                                color: "#fff",
                                backgroundColor: "#a0947c",
                                padding: "3px",
                                borderRadius: "10px",
                              }}
                            />
                            <Rating
                              name="student-rating"
                              value={student.rating || 3}
                              precision={0.5}
                              readOnly
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Box
              mt={10}
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "27px",
                  opacity: 0.3,
                  order: 2,
                  margin: "0 auto",
                }}
              >
                No students Available
              </Typography>
            </Box>
          )}
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "200px", textAlign: "center" }}
          >
            <Typography variant="h6" color="textSecondary">
              No permission to view.
            </Typography>
          </Box>
        )}
        

        {totalItems > 10 && (
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[6, 12, 24]}
              labelRowsPerPage="Items per page"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentPage;
