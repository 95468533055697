import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TablePagination,
  InputAdornment,
  Grid,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [schools, setSchools] = useState({});
  const [courses, setCourses] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const navigate = useNavigate();
  const token = getToken();
  const [success, setSuccess] = useState(null);
  const location = useLocation(); // Get location
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  // Pagination States
  const [currentPage, setCurrentPage] = useState(0); // 0-based index for pages
  const [rowsPerPage, setRowsPerPage] = useState(100); // Items per page
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [canCreate, setCanCreate] = useState(false); // Toggle between showing cropper and input
  const [canView, setCanView] = useState(false); // Toggle between showing cropper and input
  const [canUpdate, setCanUpdate] = useState(false); // Toggle between showing cropper and input
  const [canDelete, setCanDelete] = useState(false); // Toggle between showing cropper and input
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
    setCurrentPage(0); // Reset to first page whenever rows per page changes
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('token_key');
      
      if (token) {
        try {
          const roleResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions/2`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          const rolePermissions = roleResponse.data.data;
          
          // Check role permissions or set the role
          if (rolePermissions ) {
            // navigate("/unauthorized");
            // console.log("You are not authorized to create a school",rolePermissions);
            setCanCreate(rolePermissions.create);
            setCanView(rolePermissions.view);
            setCanUpdate(rolePermissions.update);
            setCanDelete(rolePermissions.delete);
          }
        } catch (error) {
          console.error("Error fetching role permissions:", error);
          // navigate("/unauthorized"); // Redirect in case of error
        }
      }
    };

    fetchPermissions();
  }, []);

  // Fetch schools and courses based on IDs
  useEffect(() => {
    const fetchSchoolsAndCourses = async () => {
      if (assignedCourses.length === 0) return;

      const schoolIds = [
        ...new Set(assignedCourses.map((assignment) => assignment.school_id)),
      ];
      const courseIds = [
        ...new Set(assignedCourses.map((assignment) => assignment.course_id)),
      ];

      try {
        const [schoolsResponse, coursesResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_BACKEND}/api/school/without_pagination`,
            {
              params: {
                pagnation: 0,
              },
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
          axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/without_pagination`,
            {
              params: {
                pagnation: 0,
              },
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
        ]);

        const schoolsData = schoolsResponse.data.data.reduce((acc, school) => {
          acc[school._id] = school.school_name;
          return acc;
        }, {});

        const coursesData = coursesResponse.data.data.reduce((acc, course) => {
          acc[course._id] = course.courses_name;
          return acc;
        }, {});

        setSchools(schoolsData);
        setCourses(coursesData);
      } catch (error) {
        setError("Failed to fetch schools and courses.");
        console.error("Error fetching schools and courses:", error);
      }
    };

    fetchSchoolsAndCourses();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = async () => {
    const token = localStorage.getItem('token_key');
      
      if (token) {
        
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions && rolePermissions.create === 0) {
          navigate("/unautherized");
        }else{
          navigate("/partner/assign_courses");
        }
      }
  };

  const handleEditCourse = async (id) => {
    const token = localStorage.getItem('token_key');
      
      if (token) {
        
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const rolePermissions = roleResponse.data.data;
        
        // Check role permissions or set the role
        if (rolePermissions && rolePermissions.update === 0) {
          // navigate("/unautherized");
          setError("Access Denied! You don't have permission to edit this course.");
          setOpenSnackbar(true);
        }else{
          navigate(`/partner/assign_courses/edit/${id}`);
        }
      }
    // navigate(`/partner/assign_courses/edit/${id}`);
  };
  // Handle category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setCurrentPage(0); // Reset to the first page when the subcategory changes
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        const roleResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const rolePermissions = roleResponse.data.data;
        if (token) {
          if (rolePermissions && rolePermissions.update === 0) {
            // navigate("/unautherized");
            setError("Access Denied! You don't have permission to edit this course.");
            setOpenSnackbar(true);
          }else{
            await axios.put(
              `${process.env.REACT_APP_BACKEND}/api/assign_course/school/delete/${courseToDelete}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setSnackbarMessage("assigned course deleted successfully");
            setOpenSnackbar(true);
            closeDeleteDialog();
            // Refresh the list after deletion
            fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list
          }
          
        }
      } catch (err) {
        setError("Failed to delete the course.");
        console.error("Error deleting course:", err);
      }
    }
  };

  // Fetch Course Categories
  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };
  useEffect(() => {
    fetchschoolSubCategories();
  }, [selectedCategory, selectedSubCategory]);

  const fetchschoolSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category/getsubcat/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolSubCategories(response.data.data || []);
        // get list of filter units
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/school/partner/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssignedCourses(subcategoryResponse1.data.data);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        // get list of filter units
        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/school/partner/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAssignedCourses(subcategoryResponse2.data.data);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/school/partner`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage + 1, // Page is 1-based on the server
              limit: rowsPerPage,
              selectedCategory, // Passing selected school category
              schoolSubCategory: selectedSubCategory, // Passing selected school
              // selectedSchool,
            },
          }
        );
        setAssignedCourses(data.data);
        setTotalItems(data.pagination.totalItems);
        setTotalPages(data.pagination.totalPages);
      }
    } catch (error) {
      setError("Error fetching schools");
      // setOpenSnackbar(true);
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAssignedCourses();
    fetchCourseCategories();
  }, [currentPage, rowsPerPage]);

  // Check if there is a snackbar message passed through state
  useEffect(() => {
    if (location.state && location.state.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage); // Update current page on pagination change
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        sx={{ background: "#e0e0e0", p: 1 }}
      >
        <Typography variant="h4" gutterBottom>
          Assigned Courses to School
        </Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar
              open={error !== null}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
          )}

          {/* <Box display="flex" flexDirection="column" padding={2}> */}

          <Grid container spacing={2} mb={2} alignItems="flex-start">
            {/* Category */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    selectedCategory
                      ? schoolCategories.find(
                          (category) => category._id === selectedCategory
                        )
                      : null
                  }
                  onChange={(event, newValue) => {
                    setSelectedCategory(newValue ? newValue._id : "");
                    fetchAssignedCourses();
                  }}
                  options={schoolCategories}
                  getOptionLabel={(option) => option.school_name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Category"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Optional: Ensures proper comparison of options and values
                  disableClearable={false} // Optional: Disables the clear button
                />
              </FormControl>
            </Grid>

           {selectedCategory && 
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={selectedSubCategory ?schoolSubCategories.find(
                    (category) => category._id === selectedSubCategory
                  ) : null}
                  onChange={(event, newValue) => {
                    // Update the selected subcategory, using _id of the selected subcategory (or empty string if no selection).
                    setSelectedSubCategory(newValue ? newValue._id : "");

                    // Trigger any further actions, like fetching related data, if necessary
                    fetchAssignedCourses();
                  }}
                  options={schoolSubCategories}
                  getOptionLabel={(option) => option.SubCategory_name || ""} // Display the subcategory name
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="School Subcategory"
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option._id === value} // Optional: Ensures proper comparison of options and values
                  disableClearable = {false} // Optional: Disables the clear button
                />
              </FormControl>
            </Grid>
}
            <Grid
              item
              xs={12}
              md={3}
              display="flex"
              justifyContent="center"
              sx={{ order: 2, marginLeft: "auto" }}
            >
              {canCreate === 1 && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddCourse}
                disabled={loading}
                sx={{ backgroundColor: "#e0e0e0", height: "40px" }}
              >
                {loading ? <CircularProgress size={24} /> : "Assign Course"}
              </Button>
              )}
            </Grid>
          </Grid>
        
          <TableContainer component={Paper}>
  {canView === 1 ? (
    assignedCourses.length === 0 ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "200px", textAlign: "center" }}
      >
        <Typography variant="h6" color="textSecondary">
          No assigned courses found.
        </Typography>
      </Box>
    ) : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20px" }}> No.</TableCell>
            <TableCell>School </TableCell>
            <TableCell>Assigned Course </TableCell>
            <TableCell>School Category</TableCell>
            <TableCell>School Subcategory</TableCell>
            <TableCell>Grade</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedCourses
            .filter((assignment) => {
              const courseName = courses[assignment.course_id] || "";
              return courseName
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            })
            .map((assignment, index) => (
              <TableRow key={assignment._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {assignment?.schoolData?.school_name || "N/A"}
                </TableCell>
                <TableCell>{courses[assignment?.course_id] || "N/A"}</TableCell>
                <TableCell>
                  {assignment?.school_category?.school_name || "N/A"}
                </TableCell>
                <TableCell>
                  {assignment?.school_sub_category?.SubCategory_name || "N/A"}
                </TableCell>
                <TableCell>{assignment?.gradeData?.gradename || "N/A"}</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" // Optional, aligns buttons horizontally in the center
                  >
                    {canUpdate === 1 && (
                      <IconButton
                        color="primary"
                        onClick={() => handleEditCourse(assignment?._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {canDelete === 1 && (
                      <IconButton
                        color="error"
                        onClick={() => openDeleteDialog(assignment?._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "200px", textAlign: "center" }}
    >
      <Typography variant="h6" color="textSecondary">
        No permission to view.
      </Typography>
    </Box>
  )}
</TableContainer>

          {/* </Box> */}
          {/* Table Pagination */}
          {totalItems > 100 && (
            <Box display="flex" justifyContent="flex-end" padding={2}>
              <TablePagination
                component="div"
                count={totalItems}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage=""
                showFirstButton
                showLastButton
              />
            </Box>
          )}
          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this course?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button color="error" onClick={handleDeleteCourse}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ListingPage;
