import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch,
  Container,
  InputAdornment,
  Grid,
  Autocomplete,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [expandedCourseId, setExpandedCourseId] = useState(null); // Track expanded course
  const [category, setCategory] = useState("" ? "" : null);
  const [subcategory, setSubcategory] = useState("");
  const [categories, setCategories] = useState([]); // List of categories fetched from backend
  const [subcategories, setSubcategories] = useState([]); // List of subcategories

  const [canCreate, setCanCreate] = useState(false);  
  const [canView, setCanView] = useState(false);  
  const [canUpdate, setCanUpdate] = useState(false);  
  const [canDelete, setCanDelete] = useState(false);  

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('token_key');
      
      if (token) {
        try {
          const roleResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/rolespermission/permissions/7`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          const rolePermissions = roleResponse.data.data;
          
          // Check role permissions or set the role
          if (rolePermissions ) {
            // navigate("/unauthorized");
            // console.log("You are not authorized to create a school",rolePermissions);
            setCanCreate(rolePermissions.create);
            setCanView(rolePermissions.view);
            setCanUpdate(rolePermissions.update);
            setCanDelete(rolePermissions.delete);
          }
        } catch (error) {
          console.error("Error fetching role permissions:", error);
          // navigate("/unauthorized"); // Redirect in case of error
        }
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    const fetchCategoriesAndSubcategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const categoryResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setCategories(categoryResponse.data.data);

          if (category && category !== "") {
            // Fetch subcategories based on selected category
            const subcategoryResponse = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${category}`,
              {
                // params: { categoryId: category },
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setSubcategories(subcategoryResponse.data.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories or subcategories:", error);
      }
    };

    fetchCategoriesAndSubcategories();
  }, [category]); // Fetch subcategories whenever category is changed

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/department`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setDepartments(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/getassigned_courses_by_partner`,
            {
              params: {
                page: page + 1,
                limit: rowsPerPage,
                searchTerm,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(response.data.data);
          console.log("courses with current user:", response.data.data);

          setTotalItems(response.data.pagination.totalItems);
        }
      } catch (err) {
        console.error("Error fetching courses:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
    fetchCourses();
  }, [page, rowsPerPage, searchTerm]);



  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const token = getToken();
      if (token) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          status: newStatus,
        });
        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course._id === id ? { ...course, status: newStatus } : course
          )
        );
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find((dep) => dep._id === departmentId);
    return department ? department.department_name : "Unknown";
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleAddCourse = () => {
    navigate("/partner/courses/add");
  };

  const handleEditCourse = (id) => {
    navigate(`/partner/courses/edit/${id}`);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/courses/delete/${courseToDelete}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourseToDelete(null);
          setIsDialogOpen(false);
          // Refresh the list after deletion
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/current-user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                page: page + 1,
                limit: rowsPerPage,
                searchTerm,
              },
            }
          );
          setCourses(response.data.data);
          setTotalItems(response.data.pagination.totalItems);
        }
      } catch (err) {
        console.error("Error deleting course:", err);
      }
    }
  };

  const openDeleteDialog = (id) => {
    setCourseToDelete(id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const toggleExpand = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };

  const filteredcourses = courses.filter((course) =>
    course.course_id.courses_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // .filter(course => course.course_id.courses_name.toLowerCase().includes(searchTerm.toLowerCase()))
  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        sx={{ background: "#e0e0e0", p: 1 }}
      >
        <Typography variant="h4" gutterBottom>
          Courses
        </Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          {/* Category Dropdown */}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  categories.find(
                    (categoryItem) => categoryItem._id === category
                  ) || null
                } // Ensure value is null for Autocomplete instead of empty string
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue._id : null); // Store full category object (or null if no selection)
                  setSubcategory(""); // Reset subcategory when category changes
                  // Make API call here with the selected category's _id
                }}
                options={categories}
                getOptionLabel={(option) => option.courses_name || ""} // Display courses_name as the label
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Ensure the correct option is selected
                // disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                // Optionally, add sorting for categories if required
                sortBy={(a, b) => a.courses_name.localeCompare(b.courses_name)}
                sx={{
                  width: "100%", // To make sure it stretches to fill the available width
                }}
              />
            </FormControl>
          </Grid>

          {/* Subcategory Dropdown */}
          {category && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    subcategories.find(
                      (subCategoryItem) => subCategoryItem._id === subcategory
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSubcategory(newValue ? newValue._id : null); // Set subcategory by its ID
                    setPage(0); // Reset to the first page when subcategory changes
                  }}
                  options={subcategories}
                  getOptionLabel={(option) =>
                    option.courses_sub_category_name || ""
                  } // Display the subcategory name
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  } // Compare by subcategory ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subcategory"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  // disableClearable
                  sx={{ width: "100%" }} // Ensure it takes full width
                  sortBy={(a, b) =>
                    a.courses_sub_category_name.localeCompare(
                      b.courses_sub_category_name
                    )
                  } // Optional: Sorting the options A-Z
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* <h1>Courses</h1> */}
      <Box display="flex" flexDirection="column" padding={2}>
        {/* <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          
        </Box> */}
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
  {canView === 1 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20px" }}> No.</TableCell>
                  <TableCell> Name</TableCell>
                  <TableCell> Thumbnail</TableCell>
                  <TableCell> Image</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>No. Of Chapters</TableCell>
                  <TableCell>Department</TableCell>
                  {/* <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredcourses.length > 0 ? (
                  filteredcourses
                    .filter((course) =>
                      course.course_id.courses_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((course, index) => (
                      <TableRow key={course.course_id._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{course.course_id.courses_name}</TableCell>
                        <TableCell>
                          {course.course_id.course_thumbnail ? (
                            <img
                              src={course.course_id.course_thumbnail}
                              alt="Course Thumbnail"
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "No image"
                          )}
                        </TableCell>
                        <TableCell>
                          {course.course_id.course_image ? (
                            <img
                              src={course.course_id.course_image}
                              alt="Course Image"
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            "No Photo"
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {expandedCourseId === course._id ? (
                              <span>
                                {course.course_id.course_details}
                                <Button
                                  onClick={() => toggleExpand(course._id)}
                                  size="small"
                                >
                                  Show Less
                                </Button>
                              </span>
                            ) : (
                              <span>
                                {course.course_id.course_details.length > 100
                                  ? `${course.course_id.course_details.slice(
                                      0,
                                      100
                                    )}...`
                                  : course.course_id.course_details}
                                {course.course_id.course_details.length >
                                  100 && (
                                  <Button
                                    onClick={() => toggleExpand(course._id)}
                                    size="small"
                                  >
                                    Read More
                                  </Button>
                                )}
                              </span>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>{course.course_id.no_of_chapters}</TableCell>
                        <TableCell>
                          {getDepartmentName(course.course_id.department_id)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No courses found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "200px", textAlign: "center" }}
            >
              <Typography variant="h6" color="textSecondary">
                No permission to view.
              </Typography>
            </Box>
          )}
          </TableContainer>
        )}
        {totalItems > 100 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        )}
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this course?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleDeleteCourse}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CourseListMain;
