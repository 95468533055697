import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import AddPlace from "./AddPlace";
import EditPlace from "../components/pages/EditPlace";
import { getToken } from "../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

function PlaceList() {
  const [places, setPlaces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict("");
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      fetchTowns(selectedDistrict);
    } else {
      setTowns([]);
      setSelectedTown("");
    }
  }, [selectedDistrict]);

  useEffect(() => {
    fetchPlaces();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedTown,
  ]);

  const fetchPlaces = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/place`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
              country: selectedCountry,
              state: selectedState,
              district: selectedDistrict,
              town: selectedTown,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPlaces(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching places");
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries", err);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/without_pagination`,
          {
            params: { country: countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch states", err);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/without_pagination`,
          {
            params: { state: stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch districts", err);
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/without_pagination`,
          {
            params: { district: districtId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch towns", err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (placeId) => {
    setSelectedPlaceId(placeId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedPlaceId(null);
  };

  const handleDeletePlace = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/place/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Place deleted successfully");
        setOpenSnackbar(true);
        fetchPlaces(); // Refresh the list after deleting
      }
    } catch (err) {
      setSnackbarMessage("Failed to delete place");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/place/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedPlace(response.data.data);
        setSelectedPlaceId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching place details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedPlaceId(null);
    setSelectedPlace(null);
  };

  const handleEditPlace = () => {
    handleCloseEditModal();
    fetchPlaces(); // Refresh the list after editing
    setSnackbarMessage("Place Updated successfully");
    setOpenSnackbar(true);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Place Listing</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        {/* Filter Dropdowns */}
        <Grid container spacing={2}>
          {/* Country Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedCountry
                    ? countries.find(
                        (country) => country._id === selectedCountry
                      )
                    : null
                }
                onChange={(e, newValue) =>
                  setSelectedCountry(newValue?._id || "")
                } // Handle selection and reset to empty if no value selected
                options={countries} // The list of countries
                getOptionLabel={(option) => option.country_name} // Label displayed in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match country _id for selection
                renderInput={(params) => (
                  <TextField {...params} label="Country" size="small" />
                )}
                // Sorting countries alphabetically
                sortBy={(a, b) => a.country_name.localeCompare(b.country_name)}
                // Enable clearable button
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>

          {/* State Filter */}
          {
            selectedCountry &&
            <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedState
                    ? states.find((state) => state._id === selectedState)
                    : null
                }
                onChange={(e, newValue) =>
                  setSelectedState(newValue?._id || "")
                } // Handle selection and reset to empty if no value selected
                options={states
                  .filter((state) => state.country === selectedCountry) // Filter states by selected country
                  .sort((a, b) => a.state_name.localeCompare(b.state_name))} // Sort states alphabetically
                getOptionLabel={(option) => option.state_name} // Label displayed in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match state _id for selection
                renderInput={(params) => (
                  <TextField {...params} label="State" size="small" />
                )}
                // Enable clearable button
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>
}
          {/* District Filter */}
          {
            selectedState&&
            <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedDistrict
                    ? districts.find(
                        (district) => district._id === selectedDistrict
                      )
                    : null
                }
                onChange={(e, newValue) =>
                  setSelectedDistrict(newValue?._id || "")
                } // Handle selection and reset to empty if no value selected
                options={districts
                  .filter((district) => district.state === selectedState) // Filter districts by selected state
                  .sort((a, b) =>
                    a.district_name.localeCompare(b.district_name)
                  )} // Sort districts alphabetically
                getOptionLabel={(option) => option.district_name} // Label displayed in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match district _id for selection
                renderInput={(params) => (
                  <TextField {...params} label="District" size="small" />
                )}
                // Enable clearable button
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>}

          {/* Town Filter */}
        { selectedDistrict &&
          
          <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <Autocomplete
              value={  selectedTown
                ? towns.find(
                    (towns) => towns._id === selectedTown
                  )
                : null}
              onChange={(e, newValue) => setSelectedTown(newValue?._id || "")} // Handle selection and reset to empty if no value selected
              options={towns
                .filter((town) => town.district === selectedDistrict) // Filter towns by selected district
                .sort((a, b) => a.Town_name.localeCompare(b.Town_name))} // Sort towns alphabetically
              getOptionLabel={(option) => option.Town_name} // Label displayed in the dropdown
              isOptionEqualToValue={(option, value) => option._id === value} // Match town _id for selection
              renderInput={(params) => (
                <TextField {...params} label="Town" size="small" />
              )}
              // Enable clearable button
              disableClearable={false} // Allow the user to clear the selection
            />
          </FormControl>
        </Grid>}

          {/* Create Button */}
          <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" sx={{ order: 2, marginLeft: "auto" }}>
      
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddModal(true)}
                sx={{
                  backgroundColor: "#e0e0e0",
                }}
              >
                Create
              </Button>
           
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell> Name</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State</TableCell>
              <TableCell>District</TableCell>
              <TableCell>Town</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {places.length > 0 ? (
              places
                .filter((place) =>
                  place.place_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((place, index) => (
                  <TableRow key={place._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>
                      {place.place_name ? place.place_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {place.country.country_name
                        ? place.country.country_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {place.state.state_name ? place.state.state_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      {place.district.district_name
                        ? place.district.district_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {place.town.Town_name ? place.town.Town_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton
                          onClick={() => handleOpenEditModal(place._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteDialog(place._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      {/* Add Place Modal */}
      <AddPlace
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
        onAdd={fetchPlaces}
        countries={countries}
        states={states}
        districts={districts}
        towns={towns}
      />
      {/* Edit Place Modal */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <EditPlace
          isOpen={openEditModal}
          onClose={() => setOpenEditModal(false)}
          place={selectedPlace}
          countries={countries}
          states={states}
          districts={districts}
          towns={towns}
          onEditPlace={() => {
            fetchPlaces();
            setSnackbarMessage("Place updated successfully");
            setOpenSnackbar(true);
          }}
        />
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this place?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeletePlace(selectedPlaceId)}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PlaceList;
