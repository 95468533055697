import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditCategoryModal from "./EditPartnerModal";
import AddPartnerModal from "./AddPartnerModal";
import { getToken } from "../../../utils/AuthUtils";

function PartnerList() {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);

  const navigate = useNavigate();

  // Fetch categories from the server
  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              search: searchTerm, // Include search term if server-side search is supported
            },
          }
        );
        setCategories(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
        setError(null);
      }
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  // Fetch categories whenever page, rowsPerPage, or searchTerm changes
  useEffect(() => {
    fetchCategories();
  }, [page, rowsPerPage, searchTerm]);

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle page change in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Open delete confirmation dialog
  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  // Close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryId(null);
  };

  // Handle category deletion
  const handleDeleteCategory = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/category/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Category deleted successfully");
        setOpenSnackbar(true);
        await fetchCategories();
      }
    } catch (err) {
      setError("Failed to delete category");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  // Open edit modal
  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/category/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSelectedCategory(response.data.data);
        setSelectedCategoryId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching category details");
      setOpenSnackbar(true);
    }
  };

  // Close edit modal
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  // Open add modal
  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  // Close add modal
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  // Handle adding a new partner
  const handleAddPartner = () => {
    handleCloseAddModal();
    fetchCategories(); // Refresh the list after adding
  };

  // Toggle category status
  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus; // Toggle between true and false
    setCategories((prev) =>
      prev.map((category) =>
        category._id === id ? { ...category, status: newStatus } : category
      )
    );

    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/partner/category/${id}/status_categorypartner`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSnackbarMessage("Category status updated successfully");
        setOpenSnackbar(true);
        fetchCategories(); // Refresh the list after status change
      }
    } catch (err) {
      setCategories((prev) =>
        prev.map((category) =>
          category._id === id
            ? { ...category, status: currentStatus }
            : category
        )
      );

      setSnackbarMessage("Failed to update category status");
      setOpenSnackbar(true);
    }
  };
  const filteredCategories = categories.filter((category) =>
    (category.partner_name || "")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Partner Category</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal} // Open Add Partner modal
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Category </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{category.partner_name}</TableCell>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    <Switch
                      checked={category.status} // Display status as checked if it's true
                      onChange={() =>
                        handleStatusToggle(category._id, category.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                        justifyContent="center"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(category._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(category._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No categories found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 100 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            rowsPerPageOptions={[10, 50, 100, 200]} // Custom pagination options
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddPartnerModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddPartner}
      />
      {selectedCategoryId && (
        <EditCategoryModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          categoryId={selectedCategoryId}
          category={selectedCategory}
          onUpdate={() => {
            handleCloseEditModal();
            fetchCategories();
            setSnackbarMessage("Category updated successfully");
            setOpenSnackbar(true); // Refresh the list after updating
          }}
        />
      )}
      {selectedCategoryId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteCategory(selectedCategoryId)}
              color="secondary"
              sx={{ backgroundColor: "#e0e0e0" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PartnerList;
