import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";  // Import useNavigate

const NotFound = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Effect to handle the redirection after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1); // Navigate two pages back in history
    }, 3000); // Delay of 3 seconds

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        bgcolor: "#f7f7f7", // Background color
        padding: 3,
      }}
    >
      <ErrorOutlineIcon
        className="notfoundsty"
        sx={{
          fontSize: "300px !important",
          color: "#e0e0e0 !important",
          mb: 2,
        }}
      />
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 1, color: "#7b7b7b" }}>
        401
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, color: "#757575" }}>
        Oops! Permission to this page is revoked.
      </Typography>
      {/* You can uncomment the button if you want to provide manual navigation */}
      {/* <Button
        variant="contained"
        color="primary"
        href="/"
        sx={{ textTransform: "none", mt: 2 }}
      >
        Go Back to Login
      </Button> */}
    </Box>
  );
};

export default NotFound;
