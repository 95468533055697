import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Container,
  CircularProgress,
  Alert,
  FormHelperText,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import { PhotoCamera } from "@mui/icons-material"; // For the file upload icon
import ImageCropper from "../ImageCropper";

const AddSchoolMain = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    School_id: "",
    school_code: "",
    school_category: "",
    school_sub_category: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    place: "",
    school_official_id: "",
    school_name: "",
    school_address: "",
    school_pin_code: "",
    school_website: "",
    school_email: "",
    principal_name: "",
    principal_contact_no: "",
    principal_email: "",
    principal_photo: "",
    admin_name: "",
    admin_contact_no: "",
    admin_email: "",
    total_number_of_students: "",
    robotics_lab_location: "",
    Rob_lab_coordinator: "",
    rob_lab_coordntr_contact_no: "",
    rob_lab_coordntr_whatsapp_no: "",
    rob_lab_coordntr_email: "",
    school_logo: "",
    social_media_linkedin: "",
    social_media_instagram: "",
    social_media_facebook: "",
    status: 1,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [principalPhoto, setPrincipalPhoto] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [principalPhotoPreview, setPrincipalPhotoPreview] = useState("");
  const [schoolLogoPreview, setSchoolLogoPreview] = useState("");
  const [principalCroppedImage, setPrincipalCroppedImage] = useState(""); // Cropped principal image
  const [schoolCroppedLogo, setSchoolCroppedLogo] = useState(""); // Cropped logo image
  const [validationErrors, setValidationErrors] = useState({});
  const [cropImageSrc, setCropImageSrc] = useState(""); // Image source for the cropper
  const [isCropping, setIsCropping] = useState(false); // Toggle between showing cropper and input
  const [isCroppingPrincipal, setIsCroppingPrincipal] = useState(false);
  const [isCroppingLogo, setIsCroppingLogo] = useState(false);
  const [currentCropType, setCurrentCropType] = useState(null);

  useEffect(() => {
    fetchCountries();
    fetchSchoolCategories();
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
    } else {
      setStates([]);
      setFormData((prev) => ({
        ...prev,
        stateId: "",
        districtId: "",
        townId: "",
      }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
    } else {
      setDistricts([]);
      setFormData((prev) => ({ ...prev, districtId: "", townId: "" }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) {
      fetchTowns(formData.districtId);
    } else {
      setTowns([]);
      setFormData((prev) => ({ ...prev, townId: "" }));
    }
  }, [formData.districtId]);

  useEffect(() => {
    if (formData.school_category) {
      const filtered = schoolSubCategories.filter(
        (subcategory) =>
          subcategory.School_category_id === formData.school_category
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [formData.school_category, schoolSubCategories]);

  useEffect(() => {
    if (principalPhoto) {
      const objectUrl = URL.createObjectURL(principalPhoto);
      setPrincipalPhotoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [principalPhoto]);

  useEffect(() => {
    if (schoolLogo) {
      const objectUrl = URL.createObjectURL(schoolLogo);
      setSchoolLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [schoolLogo]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/country?limit=300`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setCountries(response.data.data);
    } }catch (err) {
      console.error(
        "Failed to fetch countries:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`,
        {
          params: { countryId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStates(response.data.data);
    }} catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`,
        {
          params: { stateId },
          headers: {
            Authorization: `Bearer ${token}`,
          },

        }
      );
      setDistricts(response.data.data);
    } }catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/town/by-district?limit=300`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { districtId },
        }
      );
      setTowns(response.data.data);
    } }catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolCategories = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/school_category?limit=300`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSchoolCategories(response.data.data);
      fetchSchoolSubCategories(); // Fetch Subcategory here for initial population
    } }catch (err) {
      console.error(
        "Failed to fetch school categories:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolSubCategories = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/sub_school_category?limit=3000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSchoolSubCategories(response.data.data);
    } }catch (err) {
      console.error(
        "Failed to fetch school Subcategory:",
        err.response?.data?.message || err.message
      );
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const fileUrl = URL.createObjectURL(file);
      setCropImageSrc(fileUrl);

      if (name === "principal_photo") {
        setIsCroppingPrincipal(true);
        setCurrentCropType("principal");
      } else if (name === "school_logo") {
        setIsCroppingLogo(true);
        setCurrentCropType("logo");
      }
    }
  };

  // Add this helper function before handleSubmit
const dataURLtoFile = (dataurl, filename) => {
  if (!dataurl) return null;
  
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, { type: mime });
};

// Modify handleSubmit function
const handleSubmit = async () => {
  setLoading(true);
  setError("");
  setValidationErrors({});

  try {
    const token = getToken();
    if (!token) {
      alert("Please Login");
      return;
    }

    const formDataToSend = new FormData();
    
    // Append all form data except files
    for (const key in formData) {
      if (key !== 'principal_photo' && key !== 'school_logo') {
        formDataToSend.append(key, formData[key]);
      }
    }

    // Convert and append principal photo if exists
    if (principalCroppedImage) {
      console.log("principalCroppedImage", principalCroppedImage);
      
      const principalFile = dataURLtoFile(principalCroppedImage, 'principal_photo.png');
      if (principalFile) {
        formDataToSend.append('principal_photo', principalFile);
      }
    }

    // Convert and append school logo if exists
    if (schoolCroppedLogo) {
      const logoFile = dataURLtoFile(schoolCroppedLogo, 'school_logo.png');
      if (logoFile) {
        formDataToSend.append('school_logo', logoFile);
      }
    }

    await axios.post(
      `${process.env.REACT_APP_BACKEND}/api/school`,
      formDataToSend,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/partner/school_list");
  } catch (err) {
    // ...existing error handling code...
  } finally {
    setLoading(false);
  }
};

const handleCroppedImage = async (croppedData) => {
  // Convert base64 to blob
  const base64Response = await fetch(croppedData);
  const blob = await base64Response.blob();

  if (currentCropType === "principal") {
    const file = new File([blob], 'principal_photo.png', { type: 'image/png' });
    setPrincipalPhoto(file);
    setPrincipalPhotoPreview(croppedData);
    setFormData(prev => ({ ...prev, principal_photo: file }));
    setIsCroppingPrincipal(false);
  } else if (currentCropType === "logo") {
    const file = new File([blob], 'school_logo.png', { type: 'image/png' });
    setSchoolLogo(file);
    setSchoolLogoPreview(croppedData);
    setFormData(prev => ({ ...prev, school_logo: file }));
    setIsCroppingLogo(false);
  }
  setCurrentCropType(null);
};

  return (
    <Container maxWidth="100%">
      <Typography
        variant="h5"
        align="left"
        gutterBottom
        sx={{ backgroundColor: "#e4e1de", padding: "5px" }}
      >
        Add New School
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
            <Grid container spacing={2}>
              {/* School Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Short Name"
                  name="school_code"
                  value={formData.school_code}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.school_code} // Show error if it exists
                  helperText={validationErrors.school_code} // Display error message
                />
              </Grid>
              {/* School Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!validationErrors.school_category}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="school_category"
                    value={formData.school_category}
                    onChange={handleChange}
                    label="Category"
                  >
                    {schoolCategories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.school_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.school_category}</FormHelperText>
                </FormControl>
              </Grid>
              {/* School Subcategory */}
              <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!validationErrors.school_sub_category}>
                  <InputLabel>Subcategory</InputLabel>
                  <Select
                    name="school_sub_category"
                    value={formData.school_sub_category}
                    onChange={handleChange}
                    label="Subcategory"
                  >
                    {filteredSubCategories.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.SubCategory_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.school_sub_category}</FormHelperText>
                </FormControl>
              </Grid>
              {/* School Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Name"
                  name="school_name"
                  value={formData.school_name}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.school_name}
                  helperText={validationErrors.school_name}
                />
              </Grid>
              {/* School Address */}
              <Grid item xs={12}>
                <TextField
                  label="School Address"
                  name="school_address"
                  value={formData.school_address}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.school_address}
                  helperText={validationErrors.school_address}
                />
              </Grid>
              {/* School Pin Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Pin Code"
                  name="school_pin_code"
                  value={formData.school_pin_code}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={!!validationErrors.school_pin_code}
                  helperText={validationErrors.school_pin_code}
                />
              </Grid>
              {/* School Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Website"
                  name="school_website"
                  value={formData.school_website}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Email"
                  name="school_email"
                  value={formData.school_email}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.school_email}
                  helperText={validationErrors.school_email}
                  type="email"
                />
              </Grid>
              {/* Principal Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Name"
                  name="principal_name"
                  value={formData.principal_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* Principal Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Contact No"
                  name="principal_contact_no"
                  value={formData.principal_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="tel"
                />
              </Grid>
              {/* Principal Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Email"
                  name="principal_email"
                  value={formData.principal_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
              </Grid>
              {/* Principal Photo */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    Upload Principal Photo
                    <input
                      type="file"
                      name="principal_photo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                  {principalPhotoPreview && (
                    <Box mt={2}>
                      <img
                        src={principalPhotoPreview}
                        alt="Principal"
                        style={{ maxWidth: "100%", maxHeight: "150px" }}
                      />
                    </Box>
                  )}
                </FormControl>
                {isCroppingPrincipal && (
                  <Box mt={2}>
                    <ImageCropper
                      imageSrc={cropImageSrc}
                      onCrop={handleCroppedImage}
                      aspect={1}
                    />
                  </Box>
                )}
              </Grid>
              {/* Admin Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.admin_name}
                  helperText={validationErrors.admin_name}
                />
              </Grid>
              {/* Admin Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Contact No"
                  name="admin_contact_no"
                  value={formData.admin_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="tel"
                  error={!!validationErrors.admin_contact_no}
                  helperText={validationErrors.admin_contact_no}
                />
              </Grid>
              {/* Admin Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.admin_email}
                  helperText={validationErrors.admin_email}
                  type="email"
                />
              </Grid>
              {/* Total Number of Students */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Total Number of Students"
                  name="total_number_of_students"
                  value={formData.total_number_of_students}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.total_number_of_students}
                  helperText={validationErrors.total_number_of_students}
                  type="number"
                />
              </Grid>
              {/* School Logo */}
              <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!validationErrors.school_logo}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    Upload School Logo
                    <input
                      type="file"
                      name="school_logo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                  <FormHelperText>{validationErrors.school_logo}</FormHelperText>
                  {schoolLogoPreview && (
                    <Box mt={2}>
                      <img
                        src={schoolLogoPreview}
                        alt="School Logo"
                        style={{ maxWidth: "100%", maxHeight: "150px" }}
                      />
                    </Box>
                  )}
                </FormControl>
                {isCroppingLogo && (
                  <Box mt={2}>
                    <ImageCropper
                      imageSrc={cropImageSrc}
                      onCrop={handleCroppedImage}
                      aspect={1}
                    />
                  </Box>
                )}
              </Grid>
              {/* Social Media Links */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media LinkedIn"
                  name="social_media_linkedin"
                  value={formData.social_media_linkedin}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Instagram"
                  name="social_media_instagram"
                  value={formData.social_media_instagram}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Facebook"
                  name="social_media_facebook"
                  value={formData.social_media_facebook}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address Information
            </Typography>
            <Grid container spacing={2}>
              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!validationErrors.countryId}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="countryId"
                    value={formData.countryId}
                    onChange={handleChange}
                    label="Country"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.countryId}</FormHelperText>
                </FormControl>
              </Grid>
              {/* State */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!validationErrors.stateId}>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="stateId"
                    value={formData.stateId}
                    onChange={handleChange}
                    label="State"
                  >
                    {states.map((state) => (
                      <MenuItem key={state._id} value={state._id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.stateId}</FormHelperText>
                </FormControl>
              </Grid>
              {/* District */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!validationErrors.districtId}>
                  <InputLabel>District</InputLabel>
                  <Select
                    name="districtId"
                    value={formData.districtId}
                    onChange={handleChange}
                    label="District"
                  >
                    {districts.map((district) => (
                      <MenuItem key={district._id} value={district._id}>
                        {district.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.districtId}</FormHelperText>
                </FormControl>
              </Grid>
              {/* Town */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!validationErrors.townId}>
                  <InputLabel>Town</InputLabel>
                  <Select
                    name="townId"
                    value={formData.townId}
                    onChange={handleChange}
                    label="Town"
                  >
                    {towns.map((town) => (
                      <MenuItem key={town._id} value={town._id}>
                        {town.Town_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validationErrors.townId}</FormHelperText>
                </FormControl>
              </Grid>
              {/* Place */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Place"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                  fullWidth
                  error={!!validationErrors.place}
                  helperText={validationErrors.place}
                />
              </Grid>
              {/* School Official ID */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="School Official ID"
                  name="school_official_id"
                  value={formData.school_official_id}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Add School"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddSchoolMain;
